/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CheckOutSiteAction {
    CLAIM = "CLAIM",
    UNCLAIM = "UNCLAIM",
}

export enum ClientType {
    DEFAULT = "DEFAULT",
    EMPLOYEE = "EMPLOYEE",
    PITA = "PITA",
    GROUP = "GROUP",
    NONUS = "NONUS",
    SPECIAL = "SPECIAL",
    TEST = "TEST",
}

export enum Client_status {
    ARCHIVED = "ARCHIVED",
    DRAFT = "DRAFT",
    MONITORING = "MONITORING",
    REMOVAL = "REMOVAL",
    SELFREMOVAL = "SELFREMOVAL",
}

export enum DateRangeEnum {
    CUSTOM_RANGE = "CUSTOM_RANGE",
    LAST_MONTH = "LAST_MONTH",
    LAST_WEEK = "LAST_WEEK",
    THIS_MONTH = "THIS_MONTH",
    THIS_WEEK = "THIS_WEEK",
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
}

export enum DeepScanSearchStatuses {
    FAILED = "FAILED",
    FINISHED = "FINISHED",
    IN_PROGRESS = "IN_PROGRESS",
}

export enum DeepScanStatuses {
    FAILED = "FAILED",
    FOUND = "FOUND",
    NOTFOUND = "NOTFOUND",
    NOTRUN = "NOTRUN",
}

export enum OrderDirection {
    asc = "asc",
    desc = "desc",
}

export enum RemovalStatus {
    ADDEDANDREMOVED = "ADDEDANDREMOVED",
    EXIST = "EXIST",
    NOEXIST = "NOEXIST",
    REMOVALREQUESTED = "REMOVALREQUESTED",
    REMOVED = "REMOVED",
    UNKNOWN = "UNKNOWN",
}

export enum ReportStatus {
    IN_PROGRESS = "IN_PROGRESS",
    READY = "READY",
}

export enum SecurityEvent_type {
    INCORRECTPASSWORD = "INCORRECTPASSWORD",
    INVALIDPASSWORDRESETEMAIL = "INVALIDPASSWORDRESETEMAIL",
    RESTRICTEDQUERY = "RESTRICTEDQUERY",
}

export enum Site_quickSearchAllParameter {
    CITYSTATE = "CITYSTATE",
    DISABLED = "DISABLED",
    STREETADDRESS = "STREETADDRESS",
    ZIPCODE = "ZIPCODE",
}

export enum SubscriptionStatusCode {
    ACTIVE = "ACTIVE",
    CANCELED = "CANCELED",
    NOTFOUND = "NOTFOUND",
    PASTDUE = "PASTDUE",
    TRIALING = "TRIALING",
}

export enum User_authLevel {
    ADMIN = "ADMIN",
    OWNER = "OWNER",
    SELFREMOVAL = "SELFREMOVAL",
    USER = "USER",
}

export interface AddressInput {
    city: string;
    state: string;
    zipCode: string;
    streetAddress: string;
}

export interface DateRangeInput {
    type: DateRangeEnum;
    startDate?: any | null;
    endDate?: any | null;
}

export interface OrderBy {
    field: string;
    direction: OrderDirection;
}

export interface SearchBy {
    field: string;
    value: string;
}

export interface SiteInstructionInput {
    id?: string | null;
    text: string;
    link?: string | null;
}

export interface cseOptionsInput {
    threshold?: number | null;
    name: boolean;
    streetAddress: boolean;
    zipCode: boolean;
    city: boolean;
    state: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
