import { defineComponent } from 'vue'
import { ClientType } from '../../../schema-types/globalTypes'

export const TypeSelections = [
    {
        label: 'Default',
        value: ClientType.DEFAULT,
    },
    {
        label: 'Employee',
        value: ClientType.EMPLOYEE,
    },
    {
        label: 'Non-US',
        value: ClientType.NONUS,
    },
    {
        label: 'PITA',
        value: ClientType.PITA,
    },
    {
        label: 'Special',
        value: ClientType.SPECIAL,
    },
    {
        label: 'Test User',
        value: ClientType.TEST,
    },
    {
        label: 'Group',
        value: ClientType.GROUP,
    },
]

export default defineComponent({
    name: 'TypeSelector',
    props: ['modelValue'],
    data() {
        return {
            userTypes: TypeSelections,
        }
    },
    emits: ['update:modelValue'],
})
